import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { CalendarEvent } from '../../models/types'

interface GoogleContextProps {
  isSignedIn: boolean
  signIn: () => void
  signOut: () => void
  createEvent: (event: CalendarEvent) => Promise<any>
}

const GoogleContext = createContext<GoogleContextProps>({
  isSignedIn: false,
  signIn: () => {},
  signOut: () => {},
  createEvent: (_) => Promise.resolve(),
})

export const GoogleProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false)

  useEffect(() => {
    window.addEventListener('message', handleAccessTokenChange)
    return () => window.removeEventListener('message', handleAccessTokenChange)
  }, [])

  const handleAccessTokenChange = (event: MessageEvent) => {
    if(event.data.type === 'googleAccessTokenChange'){
      setIsSignedIn(!!event.data.message)
    }
  }

  const signIn = async () => {
    try {
      const windowObj = (window as any)
      if(!windowObj.checkInitialized()){
        console.error("GAPI or GIS not initialized")
        await windowObj.gapiLoad()
        await windowObj.gisInit()
      }
      windowObj['tokenClient'].requestAccessToken()
    } catch (e) {
      console.error(e)
    }
  }

  const signOut = (): void => {
    try {
      (window as any).revokeToken()
    } catch (e) {
      console.error('Error: ' + JSON.stringify(e))
    }
  }

  /**
   * Create Calendar event
   * @param {string} calendarId for the event.
   * @param {object} event with start and end dateTime
   * @param {string} sendUpdates Acceptable values are: "all", "externalOnly", "none"
   * @returns {any}
   */
  const createEvent = (event: CalendarEvent): Promise<any> => {
    try {
      const windowGapi = (window as any)['gapi']
      return windowGapi.client.calendar.events.insert({
        calendarId: 'primary',
        resource: {
          summary: event.summary,
          description: event.description,
          location: event.location,
          reminders: {
            useDefault: false,
            overrides:
              event.reminder !== undefined
                ? [{ method: 'popup', minutes: event.reminder }]
                : [],
          },
          start: {
            dateTime: event.start.dateTime,
            timeZone: event.start.timeZone, // (Formatted as an IANA Time Zone Database name, e.g. "Europe/Zurich".)
          },
          end: {
            dateTime: event.end.dateTime,
            timeZone: event.end.timeZone,
          },
        },
      })
    } catch (e: any) {
      console.error(e)
      return Promise.reject('Error: ' + JSON.stringify(e))
    }
  }

  return (
    <GoogleContext.Provider
      value={{
        isSignedIn,
        signIn,
        signOut,
        createEvent,
      }}
    >
      {children}
    </GoogleContext.Provider>
  )
}

export const useGoogle = (): GoogleContextProps => useContext(GoogleContext)
