import React from 'react'
import { Divider, Typography } from 'antd'
import { Match } from '../../../models/types'
import { formatDate } from '../../../utils/date'
import { MatchComponent } from './Match'
import image from '../../../assets/icons/Icon-BG-2.jpg'

const { Title } = Typography

interface MatchesForDateProps {
  date: string
  mapSelectedMatchIdToMatch: Map<string, Match>
  groupMatchesByDate: Map<string, Match[]>
  onMatchSelected: (match: Match) => void
  isTeamImagesLoading: boolean
  mapTeamNameToImage?: any
}

export const MatchesForDate = ({
  date,
  mapSelectedMatchIdToMatch,
  groupMatchesByDate,
  onMatchSelected,
  isTeamImagesLoading,
  mapTeamNameToImage,
}: MatchesForDateProps) => {
  return (
    <>
      <Title level={5} style={{ padding: '20px 12px', marginBottom: 0 }}>
        {formatDate(new Date(date)).date}
      </Title>
      <Divider className="divider" />
      {groupMatchesByDate.get(date)?.map((match: Match) => (
        <MatchComponent
          key={match.id}
          match={match}
          onMatchSelected={onMatchSelected}
          boxChecked={mapSelectedMatchIdToMatch.has(match.id)}
          blueTeamImage={
            !isTeamImagesLoading &&
            mapTeamNameToImage &&
            mapTeamNameToImage.hasOwnProperty(match.blueTeam)
              ? mapTeamNameToImage[match.blueTeam]
              : image
          }
          redTeamImage={
            !isTeamImagesLoading &&
            mapTeamNameToImage &&
            mapTeamNameToImage.hasOwnProperty(match.redTeam)
              ? mapTeamNameToImage[match.redTeam]
              : image
          }
        />
      ))}
    </>
  )
}
