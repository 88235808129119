import React from 'react'
import { Drawer } from '@mui/material'
import { Button, Col, Row, Tabs, Typography } from 'antd'
import { Match } from '../../models/types'
import { useMobile } from '../../services/hooks/layout.hooks'
import { GoogleCalendarExport } from './ExportMethods/GoogleCalendarExport'
import { OutlookCalendarExport } from './ExportMethods/OutlookCalendarExport'
import { SMSExport } from './ExportMethods/SMSExport'
import { ShareExport } from './ExportMethods/ShareExport'

const { Title } = Typography
const { TabPane } = Tabs

interface ExportProps {
  selectedMatchesFiltered: Match[]
  showExportDrawer: boolean
  onBack: () => void
  onClose: () => void
}

const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  } as React.CSSProperties,
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#eeeeee',
    padding: '12px',
    borderBottom: '1px solid rgba(140, 140, 140, 0.35)',
    borderTop: '1px solid rgba(140, 140, 140, 0.35)',
    height: '80px'
  } as React.CSSProperties,
  closeButton: {},
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
  },
  drawerContent: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  } as React.CSSProperties,
}

export const Export = ({
  selectedMatchesFiltered,
  showExportDrawer,
  onBack,
  onClose,
}: ExportProps) => {
  const isMobile = useMobile()

  return (
    <>
      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={showExportDrawer}
        onClose={onClose}
        style={{ height: '100vh'}}
      >
        <div style={classes.root}>
          <Row style={classes.closeButton}>
            <Col span={24} style={classes.closeButtonContainer}>
              <Button type="text" onClick={onBack}>
                Back
              </Button>
              <Button type="text" onClick={onClose}>
                Close
              </Button>
            </Col>
          </Row>
          <Row style={classes.header}>
            <Col span={24}>
              <Title level={2}>Export</Title>
            </Col>
          </Row>
          <div style={classes.drawerContent}>
            <Tabs defaultActiveKey="1" style={{ height: '100%' }}>
              <TabPane tab="Google Calendar" key="1" >
                <GoogleCalendarExport
                  selectedMatchesFiltered={selectedMatchesFiltered}
                />
              </TabPane>
              <TabPane tab="Outlook Calendar" key="2" style={{ height: '100%' }}>
                <OutlookCalendarExport
                  selectedMatchesFiltered={selectedMatchesFiltered}
                />
              </TabPane>
              <TabPane tab="SMS" key="3" style={{ height: '100%' }}>
                <SMSExport
                  selectedMatchesFiltered={selectedMatchesFiltered}
                />
              </TabPane>
              <TabPane tab="Share" key="4" style={{ height: '100%' }}>
                <ShareExport selectedMatchesFiltered={selectedMatchesFiltered}/>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Drawer>
    </>
  )
}
