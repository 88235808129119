import { useQuery } from 'react-query'
import { MatchesResponse } from '../../models/types'

const fetchMatches = async (
  matchIds: string[]
): Promise<MatchesResponse | undefined> => {
  try {
    if (matchIds.length === 0) return Promise.resolve(undefined)
    const param = matchIds.join(',')
    const res = await fetch(
      process.env.REACT_APP_API_URL + '/api/matches?tournaments=' + param
    )
    if (!res.ok) throw Error('Error in /api/matches')
    return res.json()
  } catch (e: any) {
    console.error(e)
    return Promise.reject('Error in /api/matches')
  }
}

export const useApiGetMatches = (tournamentIds: Set<string>) => {
  const tournamentIdsArray = Array.from(tournamentIds).sort()
  return useQuery(
    ['matches', ...tournamentIdsArray],
    () => fetchMatches(tournamentIdsArray),
    {
      staleTime: Infinity,
    }
  )
}
