import React, { useEffect, useState } from 'react'
import { Empty, Skeleton } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useMobile, useTablet } from '../../services/hooks/layout.hooks'
import { TeamsForTournament } from './components/TeamsForTournament'
import { getTeamAndTournamentFormattedName } from '../../utils/team'
import { useGoogleAnalytics } from '../../services/hooks/google-analytics.hooks'

interface TeamsListProps {
  isLoading: boolean
  teamNameList: string[]
  mapTeamNameToTournaments: Map<string, Set<string>>
  selectedTeamNames: Set<string>
  setSelectedTeamNames: (teams: Set<string>) => void
  isTeamImagesLoading: boolean
  mapTeamNameToImage?: any
}
export const TeamsList = ({
  isLoading,
  teamNameList,
  mapTeamNameToTournaments,
  selectedTeamNames,
  setSelectedTeamNames,
  isTeamImagesLoading,
  mapTeamNameToImage,
}: TeamsListProps) => {
  const isTablet = useTablet()
  const isMobile = useMobile()
  const googleAnalytics = useGoogleAnalytics()

  const [currentLoadedData, setCurrentLoadedData] = useState<
    Map<string, string[]>
  >(new Map())
  const [tournamentNamesSorted, setTournamentNamesSorted] = useState<string[]>(
    []
  )

  useEffect(() => {
    const groupTeamByTournament: Map<string, string[]> = new Map<
      string,
      string[]
    >()
    teamNameList.forEach((teamName: string) => {
      const tournamentNames: Set<string> =
        mapTeamNameToTournaments.get(teamName)!
      tournamentNames.forEach((tournamentName: string) => {
        if (!groupTeamByTournament.has(tournamentName)) {
          groupTeamByTournament.set(tournamentName, [])
        }
        groupTeamByTournament.get(tournamentName)?.push(teamName)
      })
    })
    groupTeamByTournament.forEach((_, key: string) => {
      groupTeamByTournament.get(key)?.sort()
    })
    setCurrentLoadedData(groupTeamByTournament)
    setTournamentNamesSorted(Array.from(groupTeamByTournament.keys()).sort())
  }, [isLoading, teamNameList, mapTeamNameToTournaments])

  const handleTeamSelected = (
    teamName: string,
    tournamentName: string
  ): void => {
    googleAnalytics.log('team.filtered_' + teamName)
    const teamAndTournamentName = getTeamAndTournamentFormattedName(
      teamName,
      tournamentName
    )
    if (!selectedTeamNames.has(teamAndTournamentName)) {
      setSelectedTeamNames(
        new Set(selectedTeamNames.add(teamAndTournamentName))
      )
    } else {
      const filteredStateArr = Array.from(selectedTeamNames).filter(
        (val) => val !== teamAndTournamentName
      )
      setSelectedTeamNames(new Set(filteredStateArr))
    }
  }

  return (
    <>
      {!isLoading ? (
        <>
          {teamNameList.length > 0 ? (
            <div
              id="scrollableTeamDiv"
              className={
                isTablet
                  ? 'tablet-scroll-section'
                  : isMobile
                  ? 'mobile-scroll-section'
                  : 'scroll-section'
              }
            >
              <InfiniteScroll
                dataLength={teamNameList.length}
                next={() => {}}
                hasMore={false}
                loader={
                  <div style={{ padding: '16px' }}>
                    <Skeleton avatar paragraph={{ rows: 1 }} active />
                  </div>
                }
                scrollableTarget="scrollableTeamDiv"
              >
                {tournamentNamesSorted.map((tournamentName: string) => (
                  <TeamsForTournament
                    key={tournamentName}
                    teamList={currentLoadedData.get(tournamentName)}
                    tournamentName={tournamentName}
                    selectedTeamNames={selectedTeamNames}
                    isTeamImagesLoading={isTeamImagesLoading}
                    mapTeamNameToImage={mapTeamNameToImage}
                    onTeamSelected={handleTeamSelected}
                  />
                ))}
                <div style={{ height: '100px' }}></div>
              </InfiniteScroll>
            </div>
          ) : (
            <Empty
              className="empty-container"
              style={{
                height: isTablet
                  ? 'calc(100vh - 201px)'
                  : isMobile
                  ? 'calc(100vh - 305.14px)'
                  : 'calc(100vh - 150px)',
              }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No results. Filter Tournaments to see results."
            />
          )}
        </>
      ) : (
        <div style={{ padding: '16px' }}>
          <Skeleton avatar paragraph={{ rows: 1 }} active />
        </div>
      )}
    </>
  )
}
