import React, { useEffect, useState } from 'react'
import { Drawer } from '@mui/material'
import { Alert, Button, Col, Divider, Row, Skeleton, Typography } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Match } from '../../models/types'
import { useMobile } from '../../services/hooks/layout.hooks'
import InfiniteScroll from 'react-infinite-scroll-component'
import { formatDate, sortByDate } from '../../utils/date'
import { useGoogleAnalytics } from '../../services/hooks/google-analytics.hooks'

const { Title, Text } = Typography

interface ConfirmationProps {
  mapSelectedMatchIdToMatch: Map<string, Match>
  setMapSelectedMatchIdToMatch: (val: Map<string, Match>) => void
  selectedMatchesFiltered: Match[]
  showConfirmationDrawer: boolean
  onClose: () => void
  onNextButtonClick: () => void
}

const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  } as React.CSSProperties,
  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '450px',
  } as React.CSSProperties,
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#eeeeee',
    padding: '12px',
    borderBottom: '1px solid rgba(140, 140, 140, 0.35)',
    borderTop: '1px solid rgba(140, 140, 140, 0.35)',
    minHeight: '100px',
  } as React.CSSProperties,
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
  },
  drawerContent: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  } as React.CSSProperties,
  selectedItemsText: {
    display: 'flex',
    alignItems: 'center',
  },
  largeNextButton: {
    width: 'auto',
    height: '50px',
    margin: '16px 32px 32px 32px',
    fontWeight: 'bold',
  },
  matchItem: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
    marginTop: '16px',
  },
  flexSpaceAround: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  } as React.CSSProperties,
  removeButtonContainer: {
    display: 'flex',
    alignItems: 'start',
  },
  matchContent: {
    display: 'flex',
    flexDirection: 'column',
  } as React.CSSProperties,
  fontGrey: {
    color: 'grey',
  } as React.CSSProperties,
  divider: { marginTop: '16px', marginBottom: '16px' },
  warningIconColour: { color: '#faad14' },
  warningIconDateContainer: {
    display: 'flex',
    gap: '4px',
  },
  alertStyle: { marginBottom: '16px', height: '124px' },
}

const FormattedDate = ({
  dateString,
  isFlexible,
  isGrey,
}: {
  dateString: string
  isFlexible: boolean
  isGrey: boolean
}) => {
  const localDate = new Date(dateString + ' UTC')
  const { date, time } = formatDate(localDate)

  return (
    <div style={classes.flexSpaceAround}>
      <div style={classes.warningIconDateContainer}>
        {isFlexible && (
          <div style={classes.warningIconColour}>
            <ExclamationCircleFilled />
          </div>
        )}
        <Text style={isGrey ? classes.fontGrey : undefined}>{date}</Text>
      </div>
      <Text style={isGrey ? classes.fontGrey : undefined}>{time}</Text>
    </div>
  )
}

export const Confirmation = ({
  mapSelectedMatchIdToMatch,
  setMapSelectedMatchIdToMatch,
  selectedMatchesFiltered,
  showConfirmationDrawer,
  onClose,
  onNextButtonClick,
}: ConfirmationProps) => {
  const isMobile = useMobile()
  const googleAnalytics = useGoogleAnalytics()

  const [loadingScroll, setLoadingScroll] = useState<boolean>(false)
  const [currentLoadedData, setCurrentLoadedData] = useState<Match[]>([])
  const [containsFlexMatch, setContainsFlexMatch] = useState<boolean>(false)
  const [showFlexWarning, setShowFlexWarning] = useState<boolean>(true)
  const [confirmDeleteID, setConfirmDeleteID] = useState<string>()

  useEffect(() => {
    setConfirmDeleteID('')
    selectedMatchesFiltered.sort((a, b) => sortByDate(a, b))
    setContainsFlexMatch(false)
    selectedMatchesFiltered.forEach((match: Match) => {
      if (match.isFlexibleStart === '1') {
        setContainsFlexMatch(true)
        setShowFlexWarning(true)
      }
    })
    setCurrentLoadedData(
      selectedMatchesFiltered.slice(0, Math.ceil(window.innerHeight / 100))
    )
  }, [selectedMatchesFiltered])

  const getMatchTitle = (match: Match): string => {
    return match.blueTeam + ' v.s ' + match.redTeam
  }

  const handleRemoveMatch = (matchId: string): void => {
    googleAnalytics.log('button.clicked_remove.match')
    const filteredMapStateArr = Array.from(mapSelectedMatchIdToMatch).filter(
      (val) => val[0] !== matchId
    )
    setMapSelectedMatchIdToMatch(new Map<string, Match>(filteredMapStateArr))
  }

  const loadMoreData = () => {
    if (loadingScroll) return
    setLoadingScroll(true)
    setCurrentLoadedData(
      currentLoadedData.concat(
        selectedMatchesFiltered.slice(
          currentLoadedData.length,
          currentLoadedData.length + 10
        )
      )
    )
    setLoadingScroll(false)
  }

  return (
    <Drawer
      anchor={isMobile ? 'bottom' : 'right'}
      open={showConfirmationDrawer}
      onClose={onClose}
    >
      <div style={isMobile ? classes.root : classes.mobileRoot}>
        <Row>
          <Col span={24} style={classes.closeButtonContainer}>
            <Button type="text" onClick={onClose}>
              Back
            </Button>
            <Button type="text" onClick={onClose}>
              Close
            </Button>
          </Col>
        </Row>
        <Row style={classes.header}>
          <Col span={24}>
            <Title level={2}>Confirmation</Title>
          </Col>
          <Col span={24} style={classes.selectedItemsText}>
            <Text strong>{selectedMatchesFiltered.length} Selected</Text>
          </Col>
        </Row>
        <div style={classes.drawerContent}>
          {containsFlexMatch && showFlexWarning && (
            <Alert
              style={classes.alertStyle}
              message="Flexible Schedule Warning"
              description={
                isMobile
                  ? 'You have selected a match with a flexible start time. It may be changed prior to the match.'
                  : 'You have selected a match with a flexible start time. We can not confirm the start time and it may be changed prior to the match.'
              }
              type="warning"
              closable
              showIcon
              onClose={() => setShowFlexWarning(false)}
            />
          )}
          <div
            id="scrollableConfirmDiv"
            className={
              containsFlexMatch && showFlexWarning
                ? 'confirmation-warning-scroll-section'
                : 'confirmation-scroll-section'
            }
          >
            <InfiniteScroll
              dataLength={currentLoadedData.length}
              next={loadMoreData}
              hasMore={
                currentLoadedData.length < selectedMatchesFiltered.length
              }
              loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
              scrollableTarget="scrollableConfirmDiv"
            >
              {currentLoadedData.map((match: Match) => (
                <div key={match.id}>
                  <Row>
                    <Col span={24} style={classes.matchItem}>
                      <div style={classes.matchContent}>
                        <Title
                          level={5}
                          style={
                            confirmDeleteID === match.id
                              ? classes.fontGrey
                              : undefined
                          }
                        >
                          {getMatchTitle(match)}
                        </Title>
                        <Text
                          strong
                          style={
                            confirmDeleteID === match.id
                              ? classes.fontGrey
                              : undefined
                          }
                        >
                          {match.tournamentName}
                        </Text>
                        <FormattedDate
                          isGrey={confirmDeleteID === match.id}
                          dateString={match.date}
                          isFlexible={match.isFlexibleStart === '1'}
                        />
                      </div>
                      <div style={classes.removeButtonContainer}>
                        {confirmDeleteID === match.id ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '8px',
                              alignContent: 'flex-end',
                            }}
                          >
                            <Text>Are you sure?</Text>
                            <div style={{ display: 'flex', gap: '4px' }}>
                              <Button
                                type="primary"
                                danger
                                className="button-danger"
                                onClick={() => handleRemoveMatch(match.id)}
                              >
                                Yes
                              </Button>
                              <Button onClick={() => setConfirmDeleteID('')}>
                                Cancel
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <Button onClick={() => setConfirmDeleteID(match.id)}>
                            Remove
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Divider className="divider" style={classes.divider} />
                </div>
              ))}
              <div style={{ height: '100px' }}></div>
            </InfiniteScroll>
          </div>
        </div>
        <Button
          type="primary"
          danger
          disabled={selectedMatchesFiltered.length === 0}
          style={classes.largeNextButton}
          onClick={onNextButtonClick}
        >
          Confirm
        </Button>
      </div>
    </Drawer>
  )
}
