import React from 'react'
import { Card, Checkbox, Divider, List, Space, Typography } from 'antd'
import image from '../../../assets/icons/Icon-BG-2.jpg'
import { getTeamAndTournamentFormattedName } from '../../../utils/team'

const { Title, Text } = Typography

interface TeamsForTournamentProps {
  teamList?: string[]
  tournamentName: string
  selectedTeamNames: Set<string>
  isTeamImagesLoading: boolean
  mapTeamNameToImage?: any
  onTeamSelected: (teamName: string, tournamentName: string) => void
}
export const TeamsForTournament = ({
  teamList = [],
  tournamentName,
  selectedTeamNames,
  isTeamImagesLoading,
  mapTeamNameToImage,
  onTeamSelected,
}: TeamsForTournamentProps) => {
  return (
    <>
      <Title level={5} style={{ marginTop: '16px' }}>
        {tournamentName}
      </Title>
      <List
        itemLayout="horizontal"
        grid={{
          column: 1,
        }}
        dataSource={teamList}
        renderItem={(team: string) => (
          <Card
            hoverable
            bordered={false}
            className="tournament-list-ant-card-body"
          >
            <List.Item
              onClick={() => onTeamSelected(team, tournamentName)}
              actions={[
                <Checkbox
                  checked={selectedTeamNames.has(
                    getTeamAndTournamentFormattedName(team, tournamentName)
                  )}
                />,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <img
                    src={
                      !isTeamImagesLoading &&
                      mapTeamNameToImage &&
                      mapTeamNameToImage.hasOwnProperty(team)
                        ? mapTeamNameToImage[team]
                        : image
                    }
                    alt="lol-esports-calendar-logo"
                    style={{ maxWidth: 80, maxHeight: 80 }}
                  />
                }
                style={
                  !selectedTeamNames.has(
                    getTeamAndTournamentFormattedName(team, tournamentName)
                  )
                    ? { opacity: 0.65 }
                    : {}
                }
                title={team}
                description={
                  <Space direction="vertical">
                    <Text>{tournamentName}</Text>
                  </Space>
                }
              />
            </List.Item>
          </Card>
        )}
      />
      <Divider className="divider" />
    </>
  )
}
