import { Match, SMSEvent } from '../models/types'

const createEventDescription = (match: Match, reminderMinutes: number) => {
  return `LoL Esports Reminder ${
    match.isFlexibleStart === '1'
      ? '\nWarning: This notification was generated when the start date was unconfirmed. This notification may have an inaccurate start time.'
      : ''
  }\n\nMatch: ${match.blueTeam} v.s. ${
    match.redTeam
  }\nStarts in: ${reminderMinutes} minutes\nTournament: ${
    match.tournamentName
  } ${match.week} Day ${match.matchDay}\nStream: ${match.stream}\nBest of: ${
    match.bestOf
  }\n\nGenerate more reminders at https://lolesportsreminder.com`
}

const createSMSEvent = (
  match: Match,
  reminderMinutes: number
): SMSEvent | null => {
  try {
    const matchDate = new Date(match.date)
    const reminderDate = new Date(matchDate.getTime() - reminderMinutes * 60000)
    return {
      body: createEventDescription(match, reminderMinutes),
      date: {
        year: reminderDate.getFullYear(),
        month: reminderDate.getMonth() + 1, // javascript january = 0, python january = 1
        date: reminderDate.getDate(),
        hours: reminderDate.getHours(),
        minutes: reminderDate.getMinutes(),
      },
    }
  } catch (e: any) {
    console.error(e)
    return null
  }
}

export const createSMSEventsList = (
  matches: Match[],
  reminderMinutes?: number
): (SMSEvent | null)[] => {
  return matches.map((match: Match) =>
    createSMSEvent(match, reminderMinutes ? reminderMinutes : 0)
  )
}
