export enum ExportStatus {
  NONE = 'none',
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum FilterTab {
  TOURNAMENT = 'tournament',
  TEAM = 'team',
}

export enum HeaderAction {
  NONE = 'none',
  FRONT = 'front',
  LANDING = 'landing',
  SHARE = 'share',
}
