import {
  getStorage,
  listAll,
  ListResult,
  ref,
  StorageReference,
  getDownloadURL,
} from 'firebase/storage'
import { useQuery } from 'react-query'

const getImageListResult = async (
  imageType: string
): Promise<ListResult> => {
  try {
    const storage = getStorage()
    const imageRef = ref(storage, imageType + '/')
    const res = await listAll(imageRef)
    return res
  } catch (e: any) {
    console.error(e)
    return Promise.reject('Unable to get' + imageType + ' image references from db')
  }
}

const getImageUrl = async (
  storageReference: StorageReference
): Promise<string[]> => {
  try {
    const downloadURL = await getDownloadURL(storageReference)
    return [storageReference.name.replace('_80x80.png', ''), downloadURL]
  } catch (e: any) {
    console.error(e)
    return Promise.reject('Unable to get image download URL')
  }
}

const fetchImages = async (imageType: string): Promise<any> => {
  try {
    const imageList: ListResult = await getImageListResult(imageType)
    const listOfAsyncCalls: Promise<any>[] = imageList.items.map(
      (val: StorageReference) => getImageUrl(val)
    )
    const resolvedAsyncCalls = await Promise.allSettled(listOfAsyncCalls)
    const res: any = {}
    resolvedAsyncCalls.forEach((result: PromiseSettledResult<any>) => {
      result.status === 'fulfilled'
        ? (res[result.value[0]] = result.value[1])
        : console.error(result.reason)
    })
    return res
  } catch (e: any) {
    console.error(e)
    return Promise.reject('Error fetching images from db')
  }
}

export const useApiGetImages = (imageType: string) => {
  return useQuery(['images', imageType], () => fetchImages(imageType), {
    staleTime: Infinity,
  })
}
