import React, { useState } from 'react'
import { Alert, Button, Input, Spin, Tooltip, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { CopyOutlined } from '@ant-design/icons'
import { Match } from '../../../models/types'
import { useApiCreateSnare } from '../../../services/api/share.api'
import { useMobile } from '../../../services/hooks/layout.hooks'
import { ExportStatus } from '../../../models/enums'
import { DESKTOP_DRAWER_CONTENT_WIDTH } from '../../../models/constants'
import { useGoogleAnalytics } from '../../../services/hooks/google-analytics.hooks'

const { Title, Text } = Typography

interface ShareExportProps {
  selectedMatchesFiltered: Match[]
}

const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '16px',
    height: '100%',
    padding: '16px',
  } as React.CSSProperties,
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '16px',
  } as React.CSSProperties,
  generateButton: {
    width: 'auto',
    minHeight: '50px',
    fontWeight: 'bold',
  },
}

export const ShareExport = ({ selectedMatchesFiltered }: ShareExportProps) => {
  const isMobile = useMobile()
  const googleAnalytics = useGoogleAnalytics()
  const { mutate, isLoading } = useApiCreateSnare()
  const [shareLink, setShareLink] = useState<string>()
  const [exportStatus, setExportStatus] = useState<ExportStatus>(
    ExportStatus.NONE
  )
  const handleCreateShare = () => {
    setExportStatus(ExportStatus.LOADING)

    googleAnalytics.log('button.clicked_export.generate.share')
    gaLogAllMatches(selectedMatchesFiltered)

    mutate(selectedMatchesFiltered, {
      onSuccess: (data) => {
        setShareLink(process.env.REACT_APP_URL + '/share/' + data.id)
        setExportStatus(ExportStatus.SUCCESS)
      },
      onError: (e) => {
        console.error(e)
        setExportStatus(ExportStatus.FAILED)
      },
    })
  }

  const gaLogAllMatches = (matches: Match[]) => {
    matches.forEach((match: Match) => {
      googleAnalytics.log('team.exported_' + match.blueTeam)
      googleAnalytics.log('team.exported_' + match.redTeam)
      googleAnalytics.log('tournament.exported_' + match.tournamentId)
    })
  }

  return (
    <>
      <div style={classes.root}>
        <div style={classes.content}>
          <div
            style={{
              maxWidth: !isMobile ? DESKTOP_DRAWER_CONTENT_WIDTH : undefined,
            }}
          >
            <Title level={3}>Generate a Share Link</Title>
            <Text type="secondary">
              Generate a URL to share with your friends so they can also get
              notified for the matches you have chosen.
            </Text>
          </div>
          {(exportStatus === ExportStatus.LOADING || isLoading) && (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          )}
          {exportStatus === ExportStatus.SUCCESS && (
            <Alert
              style={{
                maxWidth: !isMobile ? DESKTOP_DRAWER_CONTENT_WIDTH : undefined,
              }}
              message="Share Link Generation Successful"
              description="Your own personalized link can now be shared."
              type="success"
              showIcon
            />
          )}
          {exportStatus === ExportStatus.FAILED && (
            <Alert
              style={{
                maxWidth: !isMobile ? DESKTOP_DRAWER_CONTENT_WIDTH : undefined,
              }}
              message="Error"
              description="Looks like there was an issue generating a Share Link. Please wait a bit and try again."
              type="error"
              showIcon
            />
          )}
          {shareLink && (
            <Input.Group compact>
              <Input
                style={{ width: 'calc(100% - 32px)' }}
                defaultValue={shareLink}
              />
              <Tooltip title="Copy Share Link">
                <Button
                  icon={<CopyOutlined />}
                  onClick={() => {
                    navigator.clipboard.writeText(shareLink)
                  }}
                />
              </Tooltip>
            </Input.Group>
          )}
        </div>
        <Button
          type="primary"
          danger
          style={classes.generateButton}
          disabled={
            exportStatus === ExportStatus.LOADING ||
            exportStatus === ExportStatus.SUCCESS
          }
          onClick={handleCreateShare}
        >
          Generate Share Link
        </Button>
      </div>
    </>
  )
}
