import React, { useEffect } from 'react'
import { Button, Col, Row, Steps, Typography } from 'antd'
import exportGoogle from '../../assets/landing/export-google.png'
import exportSMS from '../../assets/landing/export-sms.png'
import exportShare from '../../assets/landing/export-share.png'
import filterTeams from '../../assets/landing/filter-teams.png'
import filterTournaments from '../../assets/landing/filter-tournaments.png'
import selectMatches from '../../assets/landing/select-matches.png'
import viewMatches from '../../assets/landing/view-matches.png'
import {
  FilterOutlined,
  SelectOutlined,
  EyeOutlined,
  NotificationOutlined,
  ShareAltOutlined,
  CalendarOutlined,
  PhoneOutlined,
} from '@ant-design/icons'
import { Header } from '../../components/Header/Header'
import { HeaderAction } from '../../models/enums'
import { useNavigate } from 'react-router-dom'
import { useGoogleAnalytics } from '../../services/hooks/google-analytics.hooks'

const { Step } = Steps
const { Title, Text } = Typography

const classes = {
  step: {
    padding: '16px',
  },
  stepContent: {
    padding: '16px',
  },
}

const TournamentContent = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title>
          <FilterOutlined /> Filter Tournaments
        </Title>
      </Col>
      <Col span={8}>
        <img
          src={filterTournaments}
          style={{
            width: '80%',
            display: 'flex',
            margin: 'auto',
            border: '1px solid black',
          }}
          alt="current-step"
        />
      </Col>
      <Col span={16}>
        <Title level={2}>
          <SelectOutlined /> Select your favourite Regions/Tournaments
        </Title>
        <Text style={{ fontSize: '16px' }}>
          Click on any tournaments in the Tournament filter and it will only
          show Teams and Matches from your selected Tournaments.
        </Text>
      </Col>
    </Row>
  )
}

const TeamContent = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title>
          <FilterOutlined /> Filter Teams
        </Title>
      </Col>
      <Col span={8}>
        <img
          src={filterTeams}
          style={{
            width: '80%',
            display: 'flex',
            margin: 'auto',
            border: '1px solid black',
          }}
          alt="current-step"
        />
      </Col>
      <Col span={16}>
        <Title level={2}>
          <SelectOutlined /> Select your favourite Teams
        </Title>
        <Text style={{ fontSize: '16px' }}>
          Click on any Teams in the Team filter and it will only show Matches
          from your selected Teams.
        </Text>
      </Col>
    </Row>
  )
}

const MatchContent = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title>
          <SelectOutlined /> Choose Matches
        </Title>
      </Col>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <img
              src={viewMatches}
              style={{
                width: '80%',
                display: 'flex',
                margin: 'auto',
                border: '1px solid black',
              }}
              alt="current-step"
            />
          </Col>
          <Col span={16}>
            <Title level={2}>
              <EyeOutlined /> View all matches you have filtered for
            </Title>
            <Text style={{ fontSize: '16px' }}>
              Easily view any upcoming matches for your favourite Teams and
              Tournaments.
            </Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <img
              src={selectMatches}
              style={{
                width: '80%',
                display: 'flex',
                margin: 'auto',
                border: '1px solid black',
              }}
              alt="current-step"
            />
          </Col>
          <Col span={16}>
            <Title level={2}>
              <NotificationOutlined /> Select all matches you would like to be
              notified for
            </Title>
            <Text style={{ fontSize: '16px' }}>
              Select the matches that you would like to be notified for by
              clicking on the checkboxes. When you are finished, press the Next
              button.
            </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const ExportContent = () => {
  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Title>
          <NotificationOutlined /> Set Reminders
        </Title>
      </Col>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <img
              src={exportGoogle}
              style={{
                width: '80%',
                display: 'flex',
                margin: 'auto',
                border: '1px solid black',
              }}
              alt="current-step"
            />
          </Col>
          <Col span={16}>
            <Title level={2}>
              <CalendarOutlined /> Create events for your calendar
            </Title>
            <Text style={{ fontSize: '16px' }}>
              Add events to your Calendar so you can view them alongside your
              work/school calendar.
            </Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <img
              src={exportSMS}
              style={{
                width: '80%',
                display: 'flex',
                margin: 'auto',
                border: '1px solid black',
              }}
              alt="current-step"
            />
          </Col>
          <Col span={16}>
            <Title level={2}>
              <PhoneOutlined /> Get notified through SMS
            </Title>
            <Text style={{ fontSize: '16px' }}>
              If you're on the go, get text message notifications sent to you.
            </Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <img
              src={exportShare}
              style={{
                width: '80%',
                display: 'flex',
                margin: 'auto',
                border: '1px solid black',
              }}
              alt="current-step"
            />
          </Col>
          <Col span={16}>
            <Title level={2}>
              <ShareAltOutlined /> Share with your friends
            </Title>
            <Text style={{ fontSize: '16px' }}>
              Share with friends by copying a personalized link that lets them
              set their own reminders for the matches you have chosen.
            </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const steps = [
  {
    title: 'Filter Tournaments',
    icon: <FilterOutlined />,
    content: <TournamentContent />,
  },
  {
    title: 'Filter Teams',
    icon: <FilterOutlined />,
    content: <TeamContent />,
  },
  {
    title: 'Choose Matches',
    icon: <SelectOutlined />,
    content: <MatchContent />,
  },
  {
    title: 'Set Reminders',
    icon: <NotificationOutlined />,
    content: <ExportContent />,
  },
]

export const Landing = () => {
  const googleAnalytics = useGoogleAnalytics()

  const [current, setCurrent] = React.useState(0)

  const navigate = useNavigate()

  useEffect(() => {
    googleAnalytics.log('page.view_landing')
  }, [])

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }
  return (
    <>
      <Header headerAction={HeaderAction.LANDING} />
      <div style={{ padding: '16px' }}>
        <Steps
          type="navigation"
          current={current}
          onChange={(current: number) => setCurrent(current)}
        >
          {steps.map((item) => (
            <Step key={item.title} title={item.title} icon={item.icon} />
          ))}
        </Steps>
        <div style={classes.step}>
          <Row>
            <Col span={24} style={classes.stepContent}>
              {steps[current].content}
            </Col>
          </Row>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '16px',
          }}
        >
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Previous
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => navigate('/')}>
              Done
            </Button>
          )}
        </div>
      </div>
    </>
  )
}
