import React, { useEffect, useState } from 'react'
import { Button, Empty, Skeleton } from 'antd'
import { Match } from '../../models/types'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useMobile, useTablet } from '../../services/hooks/layout.hooks'
import { sortByDate } from '../../utils/date'
import { MatchesForDate } from './components/MatchesForDate'

interface MatchesListProps {
  isLoading: boolean
  matchList: Match[]
  mapSelectedMatchIdToMatch: Map<string, Match>
  setMapSelectedMatchIdToMatch: (set: Map<string, Match>) => void
  isTeamImagesLoading: boolean
  mapTeamNameToImage?: any
  onMobileFilter: () => void
}

export const MatchesList = ({
  isLoading,
  matchList,
  mapSelectedMatchIdToMatch,
  setMapSelectedMatchIdToMatch,
  isTeamImagesLoading,
  mapTeamNameToImage,
  onMobileFilter,
}: MatchesListProps) => {
  const isMobile = useMobile()
  const isTablet = useTablet()
  const [loadingScroll, setLoadingScroll] = useState<boolean>(false)
  const [dateListSorted, setDateListSorted] = useState<string[]>([])
  const [groupMatchesByDate, setGroupMatchesByDate] = useState<
    Map<string, Match[]>
  >(new Map())
  const [currentLoadedDates, setCurrentLoadedDates] = useState<string[]>([])

  useEffect(() => {
    if (!isLoading && matchList) {
      const numMatchesNeededInitialLoad = Math.ceil(window.innerHeight / 100)
      let currentNumMatchesInitialLoad = 0
      let numDatesInitialLoad = 0

      const newGroupMatchesByDate = new Map<string, Match[]>(new Map())
      const dateList: string[] = []

      matchList
        .sort((a, b) => sortByDate(a, b))
        .forEach((match: Match) => {
          const matchDate = new Date(match.date + ' UTC').toLocaleDateString()
          if (!newGroupMatchesByDate.has(matchDate)) {
            newGroupMatchesByDate.set(matchDate, [])
            dateList.push(matchDate)
          }
          newGroupMatchesByDate.get(matchDate)?.push(match)
        })
      newGroupMatchesByDate.forEach((_, key: string) => {
        newGroupMatchesByDate
          .get(key)
          ?.sort((a: Match, b: Match) => sortByDate(a, b))
        if (currentNumMatchesInitialLoad < numMatchesNeededInitialLoad) {
          numDatesInitialLoad++
          currentNumMatchesInitialLoad += newGroupMatchesByDate.get(key)!.length
        }
      })
      setDateListSorted(dateList)
      setCurrentLoadedDates(dateList.slice(0, numDatesInitialLoad))
      setGroupMatchesByDate(newGroupMatchesByDate)
    }
  }, [isLoading, matchList])

  const handleMatchSelected = (match: Match): void => {
    if (!mapSelectedMatchIdToMatch.has(match.id)) {
      setMapSelectedMatchIdToMatch(
        new Map<string, Match>(mapSelectedMatchIdToMatch.set(match.id, match))
      )
    } else {
      const filteredMapStateArr = Array.from(mapSelectedMatchIdToMatch).filter(
        (val) => val[0] !== match.id
      )
      setMapSelectedMatchIdToMatch(new Map<string, Match>(filteredMapStateArr))
    }
  }

  const loadMoreData = () => {
    if (loadingScroll) return
    setLoadingScroll(true)
    setCurrentLoadedDates(
      currentLoadedDates.concat(
        dateListSorted.slice(
          currentLoadedDates.length,
          currentLoadedDates.length + 1
        )
      )
    )
    setLoadingScroll(false)
  }

  return (
    <>
      {!isLoading ? (
        <>
          {currentLoadedDates.length > 0 ? (
            <div
              id="scrollableMatchDiv"
              className={
                isMobile
                  ? 'mobile-match-scroll-section'
                  : isTablet
                  ? 'tablet-scroll-section'
                  : 'scroll-section'
              }
            >
              <InfiniteScroll
                dataLength={currentLoadedDates.length}
                next={loadMoreData}
                hasMore={currentLoadedDates.length < dateListSorted.length}
                loader={
                  <div style={{ padding: '16px' }}>
                    <Skeleton avatar paragraph={{ rows: 1 }} active />
                  </div>
                }
                scrollableTarget="scrollableMatchDiv"
              >
                {currentLoadedDates.map((date: string) => (
                  <div key={date}>
                    <MatchesForDate
                      date={date}
                      mapSelectedMatchIdToMatch={mapSelectedMatchIdToMatch}
                      groupMatchesByDate={groupMatchesByDate}
                      onMatchSelected={handleMatchSelected}
                      isTeamImagesLoading={isTeamImagesLoading}
                      mapTeamNameToImage={mapTeamNameToImage}
                    />
                  </div>
                ))}
                <div style={{ height: '100px' }}></div>
              </InfiniteScroll>
            </div>
          ) : (
            <Empty
              className="empty-container-matches"
              style={{
                height: isMobile
                  ? 'calc(100vh - 154px)'
                  : isTablet
                  ? 'calc(100vh - 201px)'
                  : 'calc(100vh - 150px)',
              }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No results. Filter Tournaments and Teams to see results."
            >
              {isMobile && (
                <Button type="primary" onClick={onMobileFilter}>
                  Filter
                </Button>
              )}
            </Empty>
          )}
        </>
      ) : (
        <div style={{ padding: '16px' }}>
          <Skeleton avatar paragraph={{ rows: 1 }} active />
        </div>
      )}
    </>
  )
}
