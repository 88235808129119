import { Tournament } from '../models/types'

export const showTournament = (tournament: Tournament): boolean => {
  return tournament.numMatches > 0
}

export const sortTournaments = (
  tournament1: Tournament,
  tournament2: Tournament
): number => {
  if (tournament1.name < tournament2.name) {
    return -1
  } else if (tournament1.name > tournament2.name) {
    return 1
  }
  return 0
}

export const isFeaturedTournament = (tournamentName: string) => {
  return (
    tournamentName.startsWith('LCS 20') ||
    tournamentName.startsWith('LEC') ||
    tournamentName.startsWith('LCK 20') ||
    tournamentName.startsWith('LPL') ||
    tournamentName.includes('World Championship') ||
    tournamentName.includes('Mid-Season Invitational') ||
    tournamentName.includes('MSI') ||
    tournamentName.includes('EM')
  )
}
