import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import './App.css'
import { FrontPage } from './pages/FrontPage/FrontPage'
import { initializeApp } from 'firebase/app'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { LayoutProvider } from './services/hooks/layout.hooks'
import { GoogleProvider } from './services/hooks/google.hooks'
import { OutlookProvider } from './services/hooks/outlook.hooks'
import { Share } from './pages/Share/Share'
import { Landing } from './pages/Landing/Landing'
import { PrivacyPolicy } from './pages/PrivacyPolicy/PrivacyPolicy'
import { TermsOfService } from './pages/TermsOfService/TermsOfService'
import { OutlookLoginIframe } from './pages/OutlookLoginIframe/OutlookLoginIframe'
import { GoogleAnalyticsProvider } from './services/hooks/google-analytics.hooks'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

function App() {
  const queryClient = new QueryClient()
  const app = initializeApp(firebaseConfig)

  return (
    <GoogleAnalyticsProvider app={app}>
      <GoogleProvider>
        <OutlookProvider>
          <LayoutProvider>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <Routes>
                  <Route
                    path="/terms-of-service"
                    element={<TermsOfService />}
                  />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/landing" element={<Landing />} />
                  <Route path="/share/:id" element={<Share />} />
                  <Route
                    path="/outlook-login"
                    element={<OutlookLoginIframe />}
                  />
                  <Route path="/" element={<FrontPage />} />
                </Routes>
              </BrowserRouter>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </LayoutProvider>
        </OutlookProvider>
      </GoogleProvider>
    </GoogleAnalyticsProvider>
  )
}

export default App
