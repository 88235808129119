import React, { useEffect, useState } from 'react'
import { Alert, Button, Input, Spin, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import microsoftLogo from '../../assets/icons/microsoft-logo.svg'
import microsoftDisabledLogo from '../../assets/icons/microsoft-disabled-logo.svg'
import { Match } from '../../models/types'
import { ExportStatus } from '../../models/enums'
import { createCalendarEventsList, exportEvents } from '../../utils/calendar'
import { useOutlook } from '../../services/hooks/outlook.hooks'
import { DESKTOP_DRAWER_CONTENT_WIDTH } from '../../models/constants'
import { useMobile } from '../../services/hooks/layout.hooks'
import { useGoogleAnalytics } from '../../services/hooks/google-analytics.hooks'

const { Title, Text } = Typography

const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '16px',
    height: '100vh',
    padding: '16px',
  } as React.CSSProperties,
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '16px',
  } as React.CSSProperties,
  button: {
    width: 'auto',
    minHeight: '50px',
  },
  reminderInput: {
    marginTop: '8px',
  },
  generateButton: {
    width: 'auto',
    minHeight: '50px',
    fontWeight: 'bold',
  },
  disabledGoogleButton: {
    backgroundColor: 'rgba(37, 5, 5, .08)',
    color: 'rgba(0, 0, 0, .40)',
    cursor: 'not-allowed',
    boxShadow: 'rgb(0, 0, 0, 25%) 0px 2px 4px 0px',
    width: 'auto',
    minHeight: '50px',
  },
  microsoftButton: {
    width: 'auto',
    minHeight: '50px',
    backgroundColor: '#2f2f2f',
    color: 'white',
    fontSize: '16px',
  },
  disabledMicrosoftButton: {
    width: 'auto',
    minHeight: '50px',
    backgroundColor: 'rgba(37, 5, 5, .08)',
    color: 'rgba(0, 0, 0, .40)',
    cursor: 'not-allowed',
  },
  microsoftIcon: {
    height: 50,
    width: 50,
    position: 'absolute',
    left: 0,
    top: 0,
  } as React.CSSProperties,
}

export const OutlookLoginIframe = () => {
  const isMobile = useMobile()
  const googleAnalytics = useGoogleAnalytics()
  
  const [selectedMatchesFiltered, setSelectedMatchesFiltered] = useState<Match[]>([])
  const [failedMatchExports, setFailedMatchExports] = useState<Match[]>([])
  const [exportStatus, setExportStatus] = useState<ExportStatus>(
    ExportStatus.NONE
  )
  const [reminder, setReminder] = useState<string>('15')

  const { isSignedIn, signIn, signOut, createEvent } = useOutlook()

  useEffect(() => {
    window.addEventListener('message', receiveMatches)
    return () => window.removeEventListener('message', receiveMatches)
  }, [])

  const receiveMatches = (event: MessageEvent) => {
    if (event.data.type === 'Matches'){
      setSelectedMatchesFiltered(event.data.message)
    }
  }

  const createAndExportEvents = (matches: Match[]) => {
    try {
      if (!isSignedIn) throw Error('User not signed in')

      gaLogAllMatches(matches)

      const calendarEventArr = createCalendarEventsList(
        matches,
        reminder !== '' ? Number(reminder) : undefined
      )
      exportEvents(calendarEventArr, createEvent)
        .then((res) => {
          let idx = 0
          const errorArray: Match[] = []
          res.forEach(async (val) => {
            if (val === 'EXPORT_ERROR') {
              console.error(
                'Rejected: ' +
                  JSON.stringify(
                    matches[idx].blueTeam + ' v.s. ' + matches[idx].redTeam
                  )
              )
              errorArray.push(matches[idx])
            }
            idx++
          })
          setExportStatus(
            errorArray.length === 0 ? ExportStatus.SUCCESS : ExportStatus.FAILED
          )
          setFailedMatchExports(errorArray)
        })
        .catch((e) => {
          console.error(e)
          setExportStatus(ExportStatus.FAILED)
        })
    } catch (e: any) {
      console.error(e)
    }
  }

  const handleCreateEvents = () => {
    googleAnalytics.log('button.clicked_export.generate.outlook')
    setExportStatus(ExportStatus.LOADING)
    createAndExportEvents(selectedMatchesFiltered)
  }

  const handleRetryFailedExports = () => {
    setExportStatus(ExportStatus.LOADING)
    createAndExportEvents(failedMatchExports)
  }

  const handleSignIn = () => {
    googleAnalytics.log('button.clicked_export.signin.outlook')
    signIn()
  }

  const handleSignOut = () => {
    googleAnalytics.log('button.clicked_export.signout.outlook')
    signOut()
    setExportStatus(ExportStatus.NONE)
  }

  const gaLogAllMatches = (matches: Match[]) => {
    matches.forEach((match: Match) => {
      googleAnalytics.log('team.exported_'+match.blueTeam)
      googleAnalytics.log('team.exported_'+match.redTeam)
      googleAnalytics.log('tournament.exported_'+match.tournamentId)
    })
  }

  return (
    <div style={classes.root}>
      <div style={classes.content}>
        <Title level={3}>Add to Outlook Calendar</Title>

        <Button
          onClick={handleSignIn}
          disabled={isSignedIn}
          style={
            isSignedIn
              ? classes.disabledMicrosoftButton
              : classes.microsoftButton
          }
        >
          <img
            src={isSignedIn ? microsoftDisabledLogo : microsoftLogo}
            alt="microsoftlogo"
            style={classes.microsoftIcon}
          />
          Sign in with Microsoft
        </Button>

        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <Text disabled={!isSignedIn || exportStatus !== ExportStatus.NONE}>
            Reminder time (in minutes)
          </Text>
          <Input
            type="number"
            value={reminder}
            disabled={!isSignedIn || exportStatus !== ExportStatus.NONE}
            onChange={(e) => setReminder(e.target.value)}
            style={classes.reminderInput}
          />
        </div>

        {exportStatus === ExportStatus.LOADING && (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        )}
        {exportStatus === ExportStatus.SUCCESS && (
          <Alert
            style={{ maxWidth: !isMobile ? DESKTOP_DRAWER_CONTENT_WIDTH : undefined }}
            message="Export Successful"
            description="Your events have now been added to your Outlook Calendar"
            type="success"
            showIcon
          />
        )}
        {exportStatus === ExportStatus.FAILED && (
          <>
            <Alert
              style={{ maxWidth: !isMobile ? DESKTOP_DRAWER_CONTENT_WIDTH : undefined }}
              message="Error"
              description="Looks like there was an issue exporting the following matches. Please wait a bit and try again."
              type="error"
              showIcon
            />
            {failedMatchExports.map((match: Match) => (
              <Text key={match.id}>
                {match.blueTeam} v.s {match.redTeam}
              </Text>
            ))}
            <Button
              type="primary"
              danger
              style={classes.generateButton}
              onClick={handleRetryFailedExports}
            >
              Retry failed exports
            </Button>
          </>
        )}
      </div>
      <Button
        type="primary"
        danger
        style={classes.generateButton}
        disabled={!isSignedIn || exportStatus !== ExportStatus.NONE}
        onClick={handleCreateEvents}
      >
        Generate and Export
      </Button>
      <Button
        disabled={!isSignedIn}
        onClick={handleSignOut}
        style={classes.button}
      >
        Sign out
      </Button>
    </div>
  )
}
