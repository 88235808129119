import React, { useEffect, useState } from 'react'
import { Button, Col, Divider, Row, Skeleton, Typography } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import { Match } from '../../models/types'
import { useApiGetShare } from '../../services/api/share.api'
import { Header } from '../../components/Header/Header'
import { HeaderAction } from '../../models/enums'
import InfiniteScroll from 'react-infinite-scroll-component'
import { formatDate, sortByDate } from '../../utils/date'
import { Export } from '../../components/Export/Export'
import { useMobile } from '../../services/hooks/layout.hooks'
import { useGoogleAnalytics } from '../../services/hooks/google-analytics.hooks'

const { Title, Text } = Typography

const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  } as React.CSSProperties,
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#eeeeee',
    padding: '16px',
    borderBottom: '1px solid rgba(140, 140, 140, 0.35)',
    borderTop: '1px solid rgba(140, 140, 140, 0.35)',
    minHeight: '150px',
  } as React.CSSProperties,
  mobileHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#eeeeee',
    padding: '12px',
    borderBottom: '1px solid rgba(140, 140, 140, 0.35)',
    borderTop: '1px solid rgba(140, 140, 140, 0.35)',
    height: '96px'
  } as React.CSSProperties,
  selectedItemsText: {
    display: 'flex',
    alignItems: 'center',
  },
  matchItem: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
    marginTop: '16px',
  },
  drawerContent: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  } as React.CSSProperties,
  flexSpaceAround: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  } as React.CSSProperties,
  divider: { marginTop: '16px' },
  exportContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 8px 16px 8px',
  },
  button: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  mobileButton: {
    fontWeight: 'bold',
    height: '30px'
  },
}

export const Share = () => {
  const isMobile = useMobile()
  const googleAnalytics = useGoogleAnalytics()

  const [showExportDrawer, setShowExportDrawer] = useState<boolean>(false)
  const [loadingScroll, setLoadingScroll] = useState<boolean>(false)
  const [currentLoadedData, setCurrentLoadedData] = useState<Match[]>([])

  const { data, isLoading, isError, error } = useApiGetShare(
    window.location.pathname.replace('/share/', '')
  )

  useEffect(() => {
    if (data) {
      googleAnalytics.log('page.view_share')
      data.data.matches.sort((a, b) => sortByDate(a, b))
      setCurrentLoadedData(data.data.matches.slice(0, Math.ceil(window.innerHeight / 100)))
    }
  }, [data])

  const getMatchTitle = (match: Match): string => {
    return match.blueTeam + ' v.s ' + match.redTeam
  }

  useEffect(() => {
    if (isError) console.error(error)
  }, [isError, error])

  const loadMoreData = () => {
    if (loadingScroll || !data) return
    setLoadingScroll(true)
    setCurrentLoadedData(
      currentLoadedData.concat(
        data.data.matches.slice(
          currentLoadedData.length,
          currentLoadedData.length + 10
        )
      )
    )
    setLoadingScroll(false)
  }

  const FormattedDate = ({ dateString }: { dateString: string }) => {
    const localDate = new Date(dateString + ' UTC')
    const { date, time } = formatDate(localDate)
    return (
      <div style={classes.flexSpaceAround}>
        <Text>{date}</Text>
        <Text>{time}</Text>
      </div>
    )
  }

  return (
    <>
      <Header headerAction={HeaderAction.SHARE} />
      <div style={classes.root}>
        <Row style={classes.header}>
          <Col span={24}>
            <Title level={isMobile ? 3 : 1}>Get Notified for LoL Esports</Title>
          </Col>
          <Col span={24} style={classes.selectedItemsText}>
            {data && <Text strong>{data.data.matches.length} Selected</Text>}
          </Col>
        </Row>
      </div>

      {!isLoading ? (
        <div style={classes.drawerContent}>
          <div style={classes.exportContainer}>
            <Title level={isMobile ? 4 : 2} style={{ marginBottom: 0 }}>
              Matches
            </Title>
            <Button
              type="primary"
              size={isMobile ? 'small' : 'large'}
              danger
              style={isMobile ? classes.mobileButton : classes.button}
              onClick={() => setShowExportDrawer(true)}
            >
              Set Reminders
              <CaretRightOutlined />
            </Button>
          </div>
          <div
            id="scrollableShareDiv"
            className={
              isMobile ? 'mobile-share-scroll-section' : 'share-scroll-section'
            }
          >
            <InfiniteScroll
              dataLength={currentLoadedData.length}
              next={loadMoreData}
              hasMore={
                currentLoadedData.length < (data ? data.data.matches.length : 0)
              }
              loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
              scrollableTarget="scrollableShareDiv"
            >
              {currentLoadedData.map((match: Match) => (
                <div key={match.id}>
                  <Row>
                    <Col span={24} style={classes.matchItem}>
                      <div>
                        <Title level={5}>{getMatchTitle(match)}</Title>
                        <Text strong>{match.tournamentName}</Text>
                        <FormattedDate dateString={match.date} />
                      </div>
                    </Col>
                  </Row>
                  <Divider className="divider" style={classes.divider} />
                </div>
              ))}
              <div style={{ height: '100px' }}></div>
            </InfiniteScroll>
          </div>
        </div>
      ) : (
        <div style={{ padding: '16px' }}>
          <Skeleton avatar paragraph={{ rows: 1 }} active />
        </div>
      )}
      <Export
        selectedMatchesFiltered={currentLoadedData}
        showExportDrawer={showExportDrawer}
        onBack={() => setShowExportDrawer(false)}
        onClose={() => setShowExportDrawer(false)}
      />
    </>
  )
}
