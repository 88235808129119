import React, { useEffect, useRef, useState } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Match } from '../../../models/types'

interface OutlookCalendarExportProps {
  selectedMatchesFiltered: Match[]
}

export const OutlookCalendarExport = ({
  selectedMatchesFiltered,
}: OutlookCalendarExportProps) => {
  const [loading, setLoading] = useState<boolean>(true)
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (!loading) {
      iframeRef.current?.contentWindow?.postMessage({
        type: 'Matches',
        message: selectedMatchesFiltered,
      })
    }
  }, [selectedMatchesFiltered, loading])

  return (
    <>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',height: '100%'}}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      )}
      <iframe
        onLoad={() => setLoading(false)}
        title="outlook-login-iframe"
        ref={iframeRef}
        style={{ width: '100%', height: '100%', border: 0 }}
        src={process.env.REACT_APP_URL + '/outlook-login'} // NOTE: Microsoft Export does not work on staging, only local and production
      />
    </>
  )
}
