import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import {
  AuthenticationResult,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser'
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser'
import { Client } from '@microsoft/microsoft-graph-client'
import { Event as OutlookEvent } from 'microsoft-graph'
import { CalendarEvent } from '../../models/types'

const Config = {
  appId: process.env.REACT_APP_OUTLOOK_APP_ID
    ? process.env.REACT_APP_OUTLOOK_APP_ID
    : '',
  redirectUri: window.location.href,
  scopes: ['calendars.readwrite'],
}

interface OutlookContextProps {
  isSignedIn: boolean
  signIn: () => void
  signOut: () => void
  createEvent: (event: CalendarEvent) => Promise<any>
}

const OutlookContext = createContext<OutlookContextProps>({
  isSignedIn: false,
  signIn: () => {},
  signOut: () => {},
  createEvent: (_) => Promise.resolve(),
})

export const OutlookProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false)
  const [msalInstance, setMsalInstance] =
    useState<PublicClientApplication | null>(null)
  const [graphClient, setGraphClient] = useState<Client | null>(null)

  useEffect(() => {
    const publicClientApplication = new PublicClientApplication({
      auth: {
        clientId: Config.appId,
        redirectUri: Config.redirectUri,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
      },
    })
    setMsalInstance(publicClientApplication)

    const accounts = publicClientApplication.getAllAccounts()
    if (accounts && accounts.length > 0) {
      publicClientApplication.setActiveAccount(accounts[0])
    }

    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      publicClientApplication,
      {
        account: publicClientApplication.getActiveAccount()!,
        scopes: Config.scopes,
        interactionType: InteractionType.Popup,
      }
    )

    setGraphClient(
      Client.initWithMiddleware({
        authProvider: authProvider,
      })
    )
  }, [])

  const signIn = (): void => {
    try {
      msalInstance
        ?.loginPopup({
          scopes: Config.scopes,
          prompt: 'select_account',
        })
        .then((val: AuthenticationResult) => {
          msalInstance.setActiveAccount(val.account)
          setIsSignedIn(true)
        })
        .catch((e: Error) => {
          console.error('Error: ' + JSON.stringify(e))
        })
    } catch (e: any) {
      console.error('Error: ' + JSON.stringify(e))
    }
  }
  const signOut = (): void => {
    try {
      msalInstance
        ?.logoutPopup()
        .then(() => {
          setIsSignedIn(false)
        })
        .catch((e: Error) => {
          console.error('Error: ' + JSON.stringify(e))
        })
    } catch (e: any) {
      console.error('Error: ' + JSON.stringify(e))
    }
  }

  const createEvent = async (event: CalendarEvent): Promise<any> => {
    try {
      if (!graphClient) throw Error('graphClient not defined')
      const res =  await graphClient.api('/me/events').post({
        subject: event.summary,
        body: {
          contentType: 'text',
          content: event.description
        },
        start: {
          dateTime: event.start.dateTime.toISOString(),
          timeZone: event.start.timeZone
        },
        end: {
          dateTime: event.end.dateTime.toISOString(),
          timeZone: event.end.timeZone
        },
        isReminderOn: event.reminder !== undefined,
        reminderMinutesBeforeStart: event.reminder,
        location: {
          displayName: event.location
        }
      } as OutlookEvent)
      return res
    } catch (e: any) {
      return Promise.reject('Error: ' + JSON.stringify(e))
    }
  }

  return (
    <OutlookContext.Provider value={{ isSignedIn, signIn, signOut, createEvent}}>{children}</OutlookContext.Provider>
  )
}

export const useOutlook = (): OutlookContextProps => useContext(OutlookContext)
