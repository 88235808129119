import React, { useEffect, useState } from 'react'
import { Divider, Empty, Skeleton, Typography } from 'antd'
import { Tournament, TournamentsResponse } from '../../models/types'
import InfiniteScroll from 'react-infinite-scroll-component'
import { isFeaturedTournament, showTournament, sortTournaments } from '../../utils/tournament'
import { useMobile, useTablet } from '../../services/hooks/layout.hooks'
import { TournamentsSection } from './components/TournamentsSection'
import { useGoogleAnalytics } from '../../services/hooks/google-analytics.hooks'

const { Title } = Typography

interface TournamentListProps {
  isLoading: boolean
  tournamentData?: TournamentsResponse
  selectedTournamentIds: Set<string>
  setSelectedTournamentIds: (tournamentIds: Set<string>) => void
  isTournamentImagesLoading: boolean
  mapTournamentIdToImage?: any
}

export const TournamentList = ({
  isLoading,
  tournamentData,
  selectedTournamentIds,
  setSelectedTournamentIds,
  isTournamentImagesLoading,
  mapTournamentIdToImage,
}: TournamentListProps) => {
  const isTablet = useTablet()
  const isMobile = useMobile()
  const [formattedData, setFormattedData] = useState<Tournament[]>([])
  const [loadingScroll, setLoadingScroll] = useState<boolean>(false)
  const [featuredTournaments, setFeaturedTournaments] = useState<Tournament[]>(
    []
  )
  const [currentLoadedData, setCurrentLoadedData] = useState<Tournament[]>([]) // excludes featured tournaments

  const googleAnalytics = useGoogleAnalytics()

  useEffect(() => {
    if (!isLoading && tournamentData) {
      const featuredTournaments: Tournament[] = []
      const nonFeaturedTournaments: Tournament[] = []

      const res = tournamentData.data
        .sort((a: Tournament, b: Tournament) => sortTournaments(a, b))
        .filter((tournament) => showTournament(tournament))
      res.forEach((tournament: Tournament) => {
        isFeaturedTournament(tournament.name)
          ? featuredTournaments.push(tournament)
          : nonFeaturedTournaments.push(tournament)
      })
      setFormattedData(nonFeaturedTournaments)
      setFeaturedTournaments(featuredTournaments)
      setCurrentLoadedData(
        nonFeaturedTournaments.slice(
          0,
          Math.max(
            0,
            Math.ceil(window.innerHeight / 100) - featuredTournaments.length
          )
        )
      )
    }
    // eslint-disable-next-line
  }, [isLoading, tournamentData])

  const handleTournamentSelected = (tournamentId: string) => {
    if (!selectedTournamentIds.has(tournamentId)) {
      googleAnalytics.log('tournament.filtered_' + tournamentId)
      setSelectedTournamentIds(new Set(selectedTournamentIds.add(tournamentId)))
    } else {
      const filteredStateArr = Array.from(selectedTournamentIds).filter(
        (val) => val !== tournamentId
      )
      setSelectedTournamentIds(new Set(filteredStateArr))
    }
  }

  const loadMoreData = () => {
    if (loadingScroll) return
    setLoadingScroll(true)
    setCurrentLoadedData(
      currentLoadedData.concat(
        formattedData.slice(
          currentLoadedData.length,
          currentLoadedData.length + 10
        )
      )
    )
    setLoadingScroll(false)
  }

  return (
    <>
      {!isLoading ? (
        <>
          {currentLoadedData ? (
            <div
              id="scrollableTourneyDiv"
              className={
                isTablet
                  ? 'tablet-scroll-section'
                  : isMobile
                  ? 'mobile-scroll-section'
                  : 'scroll-section'
              }
            >
              <InfiniteScroll
                dataLength={currentLoadedData.length}
                next={loadMoreData}
                hasMore={currentLoadedData.length < formattedData.length}
                loader={
                  <div style={{ padding: '16px' }}>
                    <Skeleton avatar paragraph={{ rows: 1 }} active />
                  </div>
                }
                scrollableTarget="scrollableTourneyDiv"
              >
                <Title level={5} style={{ marginTop: '16px' }}>
                  Featured Tournaments
                </Title>
                <TournamentsSection
                  tournamentList={featuredTournaments}
                  selectedTournamentIds={selectedTournamentIds}
                  isTournamentImagesLoading={isTournamentImagesLoading}
                  mapTournamentIdToImage={mapTournamentIdToImage}
                  onTournamentSelected={handleTournamentSelected}
                />
                <Divider
                  style={{ margin: '16px 0', borderTopColor: 'black' }}
                />
                <TournamentsSection
                  tournamentList={currentLoadedData}
                  selectedTournamentIds={selectedTournamentIds}
                  isTournamentImagesLoading={isTournamentImagesLoading}
                  mapTournamentIdToImage={mapTournamentIdToImage}
                  onTournamentSelected={handleTournamentSelected}
                />
                <div style={{ height: '100px' }}></div>
              </InfiniteScroll>
            </div>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </>
      ) : (
        <div style={{ padding: '16px' }}>
          <Skeleton avatar paragraph={{ rows: 1 }} active />
        </div>
      )}
    </>
  )
}
