import React from 'react'
import { Header } from '../../components/Header/Header'

export const TermsOfService = () => {
  return (
    <>
      <Header />
      <iframe
        title="terms-and-conditions"
        src="https://terms-and-conditions.lolesportscalendar.com"
        style={{ width: '100%', height: 'calc(100vh - 96px)' }}
      ></iframe>
    </>
  )
}
