import React from 'react'
import { Button, Col, Row, Tabs } from 'antd'
import { FilterTab } from '../../models/enums'

const { TabPane } = Tabs

interface TabletFilterHeaderProps {
  onSelectAllTournaments: () => void
  onClearAllTournaments: () => void
  onSelectAllTeams: () => void
  onClearAllTeams: () => void
  onFilterTabChange: (tab: FilterTab) => void
}

const classes = {
  buttons: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '4px',
    gap: '8px',
  },
  tabletFilterHeader: {
    padding: '0 12px 12px 12px',
    height: '105px',
  },
}

export const TabletFilterHeader = ({
  onSelectAllTournaments,
  onClearAllTournaments,
  onSelectAllTeams,
  onClearAllTeams,
  onFilterTabChange,
}: TabletFilterHeaderProps) => {
  return (
    <Col span={8} className="filter-border" style={classes.tabletFilterHeader}>
      <Tabs
        defaultActiveKey="1"
        centered
        onChange={(e: string) => onFilterTabChange(e as FilterTab)}
      >
        <TabPane
          tab="Filter Tournaments"
          key={FilterTab.TOURNAMENT}
          style={{ marginBottom: '4px' }}
        >
          <Row>
            <Col span={24} style={classes.buttons}>
              <Button
                size="small"
                type="primary"
                onClick={onSelectAllTournaments}
              >
                Select All
              </Button>
              <Button size="small" onClick={onClearAllTournaments}>
                Clear
              </Button>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Filter Teams" key={FilterTab.TEAM}>
          <Row>
            <Col span={24} style={classes.buttons}>
              <Button size="small" type="primary" onClick={onSelectAllTeams}>
                Select All
              </Button>
              <Button size="small" onClick={onClearAllTeams}>
                Clear
              </Button>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </Col>
  )
}
