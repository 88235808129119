import React, { useState } from 'react'
import { Button, Popover, Typography } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import image from '../../assets/logo/Logo-Transparent.png'
import { Link } from 'react-router-dom'
import { HeaderAction } from '../../models/enums'
import { useMobile } from '../../services/hooks/layout.hooks'
import { useApiGetLastUpdatedTime } from '../../services/api/last-updated-time.api'
import { useGoogleAnalytics } from '../../services/hooks/google-analytics.hooks'
import { formatLastUpdatedTime } from '../../utils/date'
import { InfoCircleOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

interface HeaderProps {
  headerAction?: HeaderAction
  openConfirmationDrawer?: () => void
  numMatchesFiltered?: number
}

const classes = {
  root: {
    padding: '16px 16px 16px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
  },
  image: { width: 'auto', height: 64 },
  mobileImage: { width: 'auto', height: 32 },
  logoContent: { display: 'flex', gap: '12px', alignItems: 'center' },
  landingLink: { marginBottom: 0, marginRight: '16px' },
  setRemindersButton: { display: 'flex', gap: '4px', alignItems: 'center' },
}

export const Header = ({
  headerAction = HeaderAction.NONE,
  openConfirmationDrawer = () => {},
  numMatchesFiltered = 0,
}: HeaderProps) => {
  const isMobile = useMobile()
  const { data: lastUpdatedTime, isLoading: isLastUpdatedTimeLoading } =
    useApiGetLastUpdatedTime()
  const googleAnalytics = useGoogleAnalytics()

  const [lastUpdatedPopoverVisible, setLastUpdatedPopoverVisible] =
    useState<boolean>(false)

  const handleSetReminders = () => {
    openConfirmationDrawer()
    googleAnalytics.log('page.view_confirmation')
  }

  const getLastUpdatedTime = (dateTime: string) => {
    return formatLastUpdatedTime(new Date(dateTime + ' UTC'))
  }

  return (
    <div style={classes.root}>
      <div style={classes.logoContent}>
        <Link to="/">
          <img
            src={image}
            style={isMobile ? classes.mobileImage : classes.image}
            alt="lolesports-logo"
          />
        </Link>
        {headerAction === HeaderAction.FRONT && (
          <>
            {!isMobile ? (
              <div style={{ display: 'flex', marginLeft: '24px' }}>
                <Link to="/landing">
                  <Title level={5} style={classes.landingLink}>
                    How it Works
                  </Title>
                </Link>
                {!isLastUpdatedTimeLoading &&
                  lastUpdatedTime &&
                  lastUpdatedTime.data &&
                  lastUpdatedTime.data.time && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                      }}
                    >
                      <InfoCircleOutlined style={{ fontSize: '16px' }} />
                      <Text>
                        Last Updated:{' '}
                        {getLastUpdatedTime(lastUpdatedTime.data.time)}
                      </Text>
                    </div>
                  )}
              </div>
            ) : (
              !isLastUpdatedTimeLoading &&
              lastUpdatedTime &&
              lastUpdatedTime.data &&
              lastUpdatedTime.data.time && (
                <Popover
                  content={getLastUpdatedTime(lastUpdatedTime.data.time)}
                  title="Last Updated:"
                  trigger="click"
                  visible={lastUpdatedPopoverVisible}
                  onVisibleChange={() =>
                    setLastUpdatedPopoverVisible(!lastUpdatedPopoverVisible)
                  }
                >
                  <InfoCircleOutlined style={{ fontSize: '16px' }} />
                </Popover>
              )
            )}
          </>
        )}
      </div>

      {headerAction === HeaderAction.FRONT &&
        (isMobile ? (
          <Link to="/landing">
            <Title level={5} style={classes.landingLink}>
              How it Works
            </Title>
          </Link>
        ) : (
          <div style={classes.setRemindersButton}>
            <Text strong>{numMatchesFiltered} Selected</Text>
            <Button
              type="primary"
              size={isMobile ? 'small' : 'large'}
              style={{
                marginLeft: '8px',
                fontWeight: 'bold',
                fontSize: !isMobile ? '16px' : undefined,
              }}
              danger
              onClick={handleSetReminders}
              disabled={numMatchesFiltered === 0}
            >
              Set Reminders
              <CaretRightOutlined />
            </Button>
          </div>
        ))}
      {headerAction === HeaderAction.SHARE && (
        <Link to="/landing">
          <Title level={5}>How it Works</Title>
        </Link>
      )}
      {headerAction === HeaderAction.LANDING && (
        <Link to="/privacy-policy">
          <Title level={5}>Privacy Policy</Title>
        </Link>
      )}
    </div>
  )
}
