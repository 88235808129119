import React from 'react'
import { Button, Col, Row, Typography } from 'antd'

const { Title } = Typography

interface DesktopFilterHeaderProps {
  onSelectAllTournaments: () => void
  onClearAllTournaments: () => void
  onSelectAllTeams: () => void
  onClearAllTeams: () => void
}

const classes = {
  buttons: {
    display: 'flex',
    gap: '8px',
    alignItems: 'flex-end'
  } as React.CSSProperties,
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '12px 12px 12px 20px',
  } as React.CSSProperties,
  titleText: {
    marginBottom: 0,
  },
  filterTitleButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}

export const DesktopFilterHeader = ({
  onSelectAllTournaments,
  onClearAllTournaments,
  onSelectAllTeams,
  onClearAllTeams,
}: DesktopFilterHeaderProps) => {
  return (
    <>
      <Col span={6} className="filter-border" style={classes.title}>
        <Row>
          <Col span={24} style={classes.filterTitleButtons}>
            <Title level={4} style={classes.titleText}>
              Tournaments
            </Title>
            <div style={classes.buttons}>
              <Button
                size="small"
                type="primary"
                onClick={onSelectAllTournaments}
              >
                Select All
              </Button>
              <Button size="small" onClick={onClearAllTournaments}>
                Clear
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={6} className="filter-border" style={classes.title}>
        <Row>
          <Col span={24} style={classes.filterTitleButtons}>
            <Title level={4} style={classes.titleText}>
              Teams
            </Title>
            <div style={classes.buttons}>
              <Button size="small" type="primary" onClick={onSelectAllTeams}>
                Select All
              </Button>
              <Button size="small" onClick={onClearAllTeams}>
                Clear
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </>
  )
}
