import { useQuery } from 'react-query'
import { LastUpdatedTimeResponse } from '../../models/types'

const fetchLastUpdatedTime = async (): Promise<LastUpdatedTimeResponse> => {
  try{
    const res = await fetch(process.env.REACT_APP_API_URL + '/api/last-updated-time')
    if(!res.ok) throw Error()
    return res.json()
  }catch (e: any){
    console.error(e)
    return Promise.reject("Data doesn't exist")
  }
}

export const useApiGetLastUpdatedTime = () => {
  return useQuery('last-updated-time', fetchLastUpdatedTime, {
    staleTime: Infinity,
  })
}
