import { SMSEvent } from '../../models/types'

export const postSMS = async (
  eventArr: (SMSEvent | null)[],
  phoneNumber: string
): Promise<any> => {
  try {
    const res = await fetch(process.env.REACT_APP_API_URL + '/api/sms', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ events: eventArr, phone_number: phoneNumber }),
    })
    if (!res.ok) throw Error('Error in /api/sms')
    return res.json()
  } catch (e: any) {
    console.error(e)
    return Promise.reject("Error in /api/sms")
  }
}
