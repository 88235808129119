import { CalendarEvent, Match } from '../models/types'

const createEventDescription = (match: Match) => {
  return `LoL Esports Reminder ${
    match.isFlexibleStart === '1'
      ? '\nWarning: This notification was generated when the start date was unconfirmed. This notification may have an inaccurate start time.'
      : ''
  }\n\nMatch: ${match.blueTeam} v.s. ${match.redTeam}\nTournament: ${
    match.tournamentName
  } ${match.week} Day ${match.matchDay}\nStream: ${match.stream}\nBest of: ${
    match.bestOf
  }\n\nGenerate more reminders at https://lolesportsreminder.com`
}

const createCalendarEvent = (
  match: Match,
  reminderMinutes?: number
): CalendarEvent | null => {
  try {
    const matchDate = new Date(match.date + ' UTC')
    const bestOf = Number(match.bestOf)
    return {
      summary: match.blueTeam + ' v.s ' + match.redTeam,
      description: createEventDescription(match),
      location: match.stream,
      reminder: reminderMinutes,
      start: {
        dateTime: matchDate,
        timeZone: 'UTC',
      },
      end: {
        dateTime: new Date(matchDate.getTime() + bestOf * 3600000),
        timeZone: 'UTC',
      },
    } as CalendarEvent
  } catch (e: any) {
    console.error(e)
    return null
  }
}

export const createCalendarEventsList = (
  matches: Match[],
  reminderMinutes?: number
): (CalendarEvent | null)[] => {
  return matches.map((match: Match) =>
    createCalendarEvent(match, reminderMinutes)
  )
}

export const exportEvents = async (
  calendarEvents: (CalendarEvent | null)[],
  createEventFn: (event: CalendarEvent) => Promise<any>
): Promise<any[]> => {
  const resultArr: any[] = []
  for (var calendarEvent of calendarEvents) {
    try {
      if (calendarEvent === null) throw Error('Event creation error')
      const res: any = await createEventFn(calendarEvent)
      resultArr.push(res)
    } catch (e: any) {
      resultArr.push('EXPORT_ERROR')
      console.error(e)
    }
  }
  return Promise.resolve(resultArr)
}
