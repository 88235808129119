import React from 'react'
import { Header } from '../../components/Header/Header'

export const PrivacyPolicy = () => {
  return (
      <>
      <Header />
      <iframe
        title="privacy-policy"
        src="https://privacy-policy.lolesportscalendar.com"
        style={{ width: '100%', height: 'calc(100vh - 96px)' }}
      ></iframe>
      </>
  )
}
