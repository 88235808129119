import React, { useEffect, useState } from 'react'
import { MatchesList } from '../../components/MatchesList/MatchesList'
import { TournamentList } from '../../components/TournamentList/TournamentList'
import { useApiGetMatches } from '../../services/api/matches.api'
import { useApiGetTournaments } from '../../services/api/tournaments.api'
import { Col, Row } from 'antd'
import { TeamsList } from '../../components/TeamsList/TeamsList'
import { useApiGetImages } from '../../services/api/images.api'
import { Confirmation } from '../../components/Confirmation/Confirmation'
import { Match, Tournament } from '../../models/types'
import { Export } from '../../components/Export/Export'
import { FilterHeader } from '../../components/FilterHeader/FilterHeader'
import { isFeaturedTournament, showTournament } from '../../utils/tournament'
import { FilterWrapper } from '../../components/FilterWrapper/FilterWrapper'
import { useTablet, useMobile } from '../../services/hooks/layout.hooks'
import { FilterTab, HeaderAction } from '../../models/enums'
import { Header } from '../../components/Header/Header'
import { getTeamAndTournamentFormattedName } from '../../utils/team'
import { useGoogleAnalytics } from '../../services/hooks/google-analytics.hooks'
import { PersonalAd } from '../../components/PersonalAd/PersonalAd'

export const FrontPage = () => {
  const isTablet = useTablet()
  const isMobile = useMobile()
  const googleAnalytics = useGoogleAnalytics()

  const [currentFilterTab, setCurrentFilterTab] = useState<FilterTab>(
    FilterTab.TOURNAMENT
  )

  const [selectedTournamentIds, setSelectedTournamentIds] = useState<
    Set<string>
  >(new Set<string>())
  const [selectedTeamNames, setSelectedTeamNames] = useState<Set<string>>(
    new Set<string>() // format: teamName////tournamentName, see getTeamAndTournamentFormattedName in tournament.ts
  )
  const [selectedMatchIdsMappedToMatch, setSelectedMatchIdsMappedToMatch] =
    useState<Map<string, Match>>(new Map<string, Match>()) // selected matches

  const { data: tournamentData, isLoading: isTournamentLoading } =
    useApiGetTournaments()
  const { data: matchData, isLoading: isMatchLoading } = useApiGetMatches(
    selectedTournamentIds
  )

  const [mapTeamNameToTournaments, setMapTeamNameToTournaments] = useState<
    Map<string, Set<string>>
  >(new Map<string, Set<string>>()) // keys are the list of all team names
  const [matchList, setMatchList] = useState<Match[]>([])

  const { data: tournamentImageMap, isLoading: isTournamentImagesLoading } =
    useApiGetImages('TournamentImages')
  const { data: teamImageMap, isLoading: isTeamImagesLoading } =
    useApiGetImages('TeamImages')

  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true)

  const [showConfirmationDrawer, setShowConfirmationDrawer] =
    useState<boolean>(false)
  const [showExportDrawer, setShowExportDrawer] = useState<boolean>(false)
  const [showFilterDrawer, setShowFilterDrawer] = useState<boolean>(false)

  // set default selected tournaments
  useEffect(() => {
    if (tournamentData && isInitialLoad) {
      const defaultSelectedTournaments = new Set<string>()
      tournamentData.data.forEach((tournament: Tournament) => {
        if (isFeaturedTournament(tournament.name)) {
          defaultSelectedTournaments.add(tournament.id)
        }
      })
      setSelectedTournamentIds(defaultSelectedTournaments)
    }
  }, [tournamentData])

  // set default selected teams
  useEffect(() => {
    if (matchData && isInitialLoad) {
      const defaultSelectedTeamNames = new Set<string>()
      matchData.data.forEach((match: Match) => {
        defaultSelectedTeamNames.add(
          getTeamAndTournamentFormattedName(
            match.blueTeam,
            match.tournamentName
          )
        )
        defaultSelectedTeamNames.add(
          getTeamAndTournamentFormattedName(match.redTeam, match.tournamentName)
        )
      })
      setSelectedTeamNames(defaultSelectedTeamNames)
      setIsInitialLoad(false)
    }
  }, [matchData])

  // update match list when tournament selection changes or when team selection changes
  // Note: include in match list if selected (even when filtered out)
  useEffect(() => {
    const newMatchList: Map<string, Match> = new Map(
      selectedMatchIdsMappedToMatch
    )
    const newTeamList = new Map<string, Set<string>>()
    if (matchData) {
      matchData.data.forEach((match: Match) => {
        if (!newTeamList.has(match.blueTeam)) {
          newTeamList.set(match.blueTeam, new Set<string>())
        }
        if (!newTeamList.has(match.redTeam)) {
          newTeamList.set(match.redTeam, new Set<string>())
        }
        newTeamList.get(match.blueTeam)?.add(match.tournamentName)
        newTeamList.get(match.redTeam)?.add(match.tournamentName)
      })
      matchData.data.forEach((match: Match) => {
        if (
          selectedTeamNames.has(
            getTeamAndTournamentFormattedName(
              match.blueTeam,
              match.tournamentName
            )
          ) ||
          selectedTeamNames.has(
            getTeamAndTournamentFormattedName(
              match.redTeam,
              match.tournamentName
            )
          )
        ) {
          newMatchList.set(match.id, match)
        }
      })
    }
    setMatchList(Array.from(newMatchList.values()))
    setMapTeamNameToTournaments(newTeamList)
  }, [matchData, selectedTournamentIds, selectedTeamNames])

  const handleConfirmationNext = () => {
    googleAnalytics.log('button.clicked_confirmation.next')
    setShowConfirmationDrawer(false)
    setShowExportDrawer(true)
  }

  const handleExportBack = () => {
    setShowConfirmationDrawer(true)
    setShowExportDrawer(false)
  }

  const handleSelectAllTournaments = () => {
    googleAnalytics.log('button.clicked_selectall.tournaments')
    const allTournamentIdsSet = new Set<string>()
    tournamentData?.data.forEach((val: Tournament) => {
      if (showTournament(val)) {
        googleAnalytics.log('tournament.filtered_' + val.id)
        allTournamentIdsSet.add(val.id)
      }
    })
    setSelectedTournamentIds(allTournamentIdsSet)
  }

  const handleClearAllTournaments = () => {
    googleAnalytics.log('button.clicked_clearall.tournaments')
    setSelectedTournamentIds(new Set<string>())
  }

  const handleSelectAllTeams = () => {
    googleAnalytics.log('button.clicked_selectall.teams')
    const allTeamNamesSet = new Set<string>()
    mapTeamNameToTournaments.forEach(
      (tournaments: Set<string>, teamName: string) => {
        googleAnalytics.log('team.filtered_' + teamName)
        tournaments.forEach((tournamentName: string) => {
          allTeamNamesSet.add(
            getTeamAndTournamentFormattedName(teamName, tournamentName)
          )
        })
      }
    )
    setSelectedTeamNames(allTeamNamesSet)
  }

  const handleClearAllTeams = () => {
    googleAnalytics.log('button.clicked_clearall.teams')
    setSelectedTeamNames(new Set<string>())
  }

  const handleSelectAllMatches = () => {
    googleAnalytics.log('button.clicked_selectall.matches')
    const allMatchesMap = new Map<string, Match>()
    matchList.forEach((val: Match) => {
      allMatchesMap.set(val.id, val)
    })
    setSelectedMatchIdsMappedToMatch(allMatchesMap)
  }

  const handleClearAllMatches = () => {
    googleAnalytics.log('button.clicked_clearall.matches')
    setSelectedMatchIdsMappedToMatch(new Map<string, Match>())
  }

  const handleTabChange = (tab: FilterTab) => {
    setCurrentFilterTab(tab)
  }

  const handleMobileFilter = () => {
    if (!showFilterDrawer) {
      googleAnalytics.log('page.view_mobile.filter')
    }
    setShowFilterDrawer(!showFilterDrawer)
  }

  return (
    <>
      <Header
        headerAction={HeaderAction.FRONT}
        openConfirmationDrawer={() => setShowConfirmationDrawer(true)}
        numMatchesFiltered={selectedMatchIdsMappedToMatch.size}
      />
      <FilterHeader
        onSelectAllTournaments={handleSelectAllTournaments}
        onClearAllTournaments={handleClearAllTournaments}
        onSelectAllTeams={handleSelectAllTeams}
        onClearAllTeams={handleClearAllTeams}
        onSelectAllMatches={handleSelectAllMatches}
        onClearAllMatches={handleClearAllMatches}
        onFilterTabChange={handleTabChange}
        openConfirmationDrawer={() => setShowConfirmationDrawer(true)}
        numMatchesFiltered={selectedMatchIdsMappedToMatch.size}
        onMobileFilter={handleMobileFilter}
      />
      <Row>
        <FilterWrapper
          currentTab={currentFilterTab}
          tournamentList={
            <TournamentList
              isLoading={isTournamentLoading}
              tournamentData={tournamentData}
              selectedTournamentIds={selectedTournamentIds}
              setSelectedTournamentIds={setSelectedTournamentIds}
              isTournamentImagesLoading={isTournamentImagesLoading}
              mapTournamentIdToImage={tournamentImageMap}
            />
          }
          teamsList={
            <TeamsList
              isLoading={isMatchLoading}
              teamNameList={Array.from(mapTeamNameToTournaments.keys()).sort()}
              mapTeamNameToTournaments={mapTeamNameToTournaments}
              selectedTeamNames={selectedTeamNames}
              setSelectedTeamNames={setSelectedTeamNames}
              isTeamImagesLoading={isTeamImagesLoading}
              mapTeamNameToImage={teamImageMap}
            />
          }
          showFilterDrawer={showFilterDrawer}
          onMobileFilter={handleMobileFilter}
          onSelectAllTournaments={handleSelectAllTournaments}
          onClearAllTournaments={handleClearAllTournaments}
          onSelectAllTeams={handleSelectAllTeams}
          onClearAllTeams={handleClearAllTeams}
        />
        <Col span={isTablet ? 16 : isMobile ? 24 : 12}>
          <MatchesList
            isLoading={isMatchLoading}
            matchList={matchList}
            mapSelectedMatchIdToMatch={selectedMatchIdsMappedToMatch}
            setMapSelectedMatchIdToMatch={setSelectedMatchIdsMappedToMatch}
            isTeamImagesLoading={isTeamImagesLoading}
            mapTeamNameToImage={teamImageMap}
            onMobileFilter={handleMobileFilter}
          />
        </Col>
        <Confirmation
          mapSelectedMatchIdToMatch={selectedMatchIdsMappedToMatch}
          setMapSelectedMatchIdToMatch={setSelectedMatchIdsMappedToMatch}
          selectedMatchesFiltered={Array.from(
            selectedMatchIdsMappedToMatch.values()
          )}
          showConfirmationDrawer={showConfirmationDrawer}
          onClose={() => setShowConfirmationDrawer(false)}
          onNextButtonClick={handleConfirmationNext}
        />
        <Export
          selectedMatchesFiltered={Array.from(
            selectedMatchIdsMappedToMatch.values()
          )}
          showExportDrawer={showExportDrawer}
          onBack={handleExportBack}
          onClose={() => setShowExportDrawer(false)}
        />
      </Row>
      <PersonalAd />
    </>
  )
}
