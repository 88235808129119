import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

const LayoutContext = createContext<{ width: number }>({ width: 0 })

export const LayoutProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const [width, setWidth] = useState<number>(window.innerWidth)

  const handleWindowResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  })

  const setMobileConfirmationScrollHeight = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  }

  useEffect(() => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    window.addEventListener('resize', setMobileConfirmationScrollHeight)
    return () => window.removeEventListener('resize', setMobileConfirmationScrollHeight)
  })

  return (
    <LayoutContext.Provider value={{ width }}>
      {children}
    </LayoutContext.Provider>
  )
}

export const useXSMobile = (): boolean => useContext(LayoutContext).width <= 600

export const useMobile = (): boolean => useContext(LayoutContext).width <= 900

export const useTablet = (): boolean => {
  const width = useContext(LayoutContext).width
  return width > 900 && width <= 1200
}

export const useDesktop = (): boolean => useContext(LayoutContext).width > 1200
