import React from 'react'
import { Button, Col, Row, Typography } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import {
  useDesktop,
  useMobile,
  useTablet,
} from '../../services/hooks/layout.hooks'
import { FilterTab } from '../../models/enums'
import { TabletFilterHeader } from './TabletFilterHeader'
import { DesktopFilterHeader } from './DesktopFilterHeader'

const { Text, Title } = Typography

interface FilterHeaderProps {
  onSelectAllTournaments: () => void
  onClearAllTournaments: () => void
  onSelectAllTeams: () => void
  onClearAllTeams: () => void
  onSelectAllMatches: () => void
  onClearAllMatches: () => void
  onFilterTabChange: (tab: FilterTab) => void
  openConfirmationDrawer: () => void
  numMatchesFiltered: number
  onMobileFilter: () => void
}

const classes = {
  root: {
    backgroundColor: '#eeeeee',
  },
  tabletRoot: {
    backgroundColor: '#eeeeee',
    height: '105px',
  },
  mobileRoot: {
    backgroundColor: '#eeeeee',
  },
  titleText: {
    marginBottom: 0,
  },
  matchHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '12px',
    border: '1px solid rgba(140, 140, 140, 0.35)',
  } as React.CSSProperties,
  filterTitleButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonStart: { display: 'flex', gap: '8px', alignItems: 'flex-start' },
}

export const FilterHeader = ({
  onSelectAllTournaments,
  onClearAllTournaments,
  onSelectAllTeams,
  onClearAllTeams,
  onSelectAllMatches,
  onClearAllMatches,
  onFilterTabChange,
  openConfirmationDrawer,
  numMatchesFiltered,
  onMobileFilter,
}: FilterHeaderProps) => {
  const isTablet = useTablet()
  const isDesktop = useDesktop()
  const isMobile = useMobile()

  return (
    <Row
      style={
        isTablet
          ? classes.tabletRoot
          : isMobile
          ? classes.mobileRoot
          : classes.root
      }
    >
      {isTablet ? (
        <TabletFilterHeader
          onSelectAllTournaments={onSelectAllTournaments}
          onClearAllTournaments={onClearAllTournaments}
          onSelectAllTeams={onSelectAllTeams}
          onClearAllTeams={onClearAllTeams}
          onFilterTabChange={onFilterTabChange}
        />
      ) : (
        isDesktop && (
          <DesktopFilterHeader
            onSelectAllTournaments={onSelectAllTournaments}
            onClearAllTournaments={onClearAllTournaments}
            onSelectAllTeams={onSelectAllTeams}
            onClearAllTeams={onClearAllTeams}
          />
        )
      )}
      <Col
        span={isTablet ? 16 : isDesktop ? 12 : 24}
        style={classes.matchHeader}
      >
        <Row>
          <Col span={24} style={classes.filterTitleButtons}>
            {isMobile ? (
              <Button size="small" onClick={onMobileFilter}>
                Filter
              </Button>
            ) : (
              <Title level={4} style={classes.titleText}>
                Matches
              </Title>
            )}

            <div style={classes.buttonStart}>
              <Button size="small" type="primary" onClick={onSelectAllMatches}>
                Select All
              </Button>
              <Button size="small" onClick={onClearAllMatches}>
                Clear
              </Button>
            </div>
          </Col>
        </Row>
        {isMobile && (
          <Row style={{ paddingTop: '16px' }}>
            <Col span={24} style={classes.filterTitleButtons}>
              <Text strong>{numMatchesFiltered} Selected</Text>
              <Button
                type="primary"
                size={isMobile ? 'small' : 'large'}
                style={{
                  marginLeft: '8px',
                  fontWeight: 'bold',
                  fontSize: !isMobile ? '16px' : undefined,
                }}
                danger
                onClick={openConfirmationDrawer}
                disabled={numMatchesFiltered === 0}
              >
                Set Reminders
                <CaretRightOutlined />
              </Button>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  )
}
