import React from 'react'
import { Alert, Typography } from 'antd'

const { Text } = Typography

const classes = {
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  } as React.CSSProperties,
  banner: {
    textAlign: 'center',
  } as React.CSSProperties,
}

export const PersonalAd = () => {
  return (
    <div style={classes.root}>
      <Alert
        style={classes.banner}
        message={
          <Text>
            Advertise your company here. Contact{' '}
            <a href="mailto:advertisement@lolesportsreminder.com">
              advertisement@lolesportsreminder.com
            </a>
          </Text>
        }
        banner
        closable
      />
    </div>
  )
}
