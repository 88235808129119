import React, { createContext, ReactNode, useContext } from 'react'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { FirebaseApp } from 'firebase/app'

interface GoogleProviderProps {
  app: FirebaseApp
  children: ReactNode
}

interface GoogleAnalyticsContextProps {
  log: (eventName: string, eventParams?: any) => void
}

const GoogleAnalyticsContext = createContext<GoogleAnalyticsContextProps>({
  log: (_) => {},
})

export const GoogleAnalyticsProvider = ({
  app,
  children,
}: GoogleProviderProps): JSX.Element => {
  const analytics = getAnalytics(app)

  const log = (eventName: string, eventParams?: any) => {
    try{
      logEvent(analytics, eventName, eventParams)
    }catch (e: any) {
      console.error(e)
    }
  }
  return (
    <GoogleAnalyticsContext.Provider value={{ log }}>
      {children}
    </GoogleAnalyticsContext.Provider>
  )
}

export const useGoogleAnalytics = (): GoogleAnalyticsContextProps => useContext(GoogleAnalyticsContext)